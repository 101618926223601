/** @jsx jsx */
import { jsx, Flex, Styled } from 'theme-ui';

import Link from '../components/link';
import Layout from '../components/layout';

export default function Home() {
  return (
    <Layout>
      <Flex
        sx={{
          height: '85vh',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'white',
        }}
      >
        <section>
          <Styled.h3>
            Nu kom du fel, testa gå <Link href="/" title="tillbaks" />
          </Styled.h3>
        </section>
      </Flex>
    </Layout>
  );
}
